/*********************************************
:: Contact Page                   
********************************************/
.page-contact {

    .contact-container {
        display: inline-block;
        width: 100%;

        h3 {
            padding: 0 0 6px 0;

            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            color: $color-brand-1;

            border-bottom: 1px solid #dbdbdb;
        }

        .tabs {
            display: inline-block;
            width: 100%;

            @include mappy-bp(mediumish) {
                margin: -13px 0 35px;
            }

            border-bottom: 1px solid #dbdbdb;

            a {
                float: left;
                padding: 5px 20px;

                font-size: 16px;
                font-weight: $font-bold;
                color: #4a4a4a;

                border: 2px solid #dbdbdb;
                border-bottom: none;
                transition: all 0s ease-in-out;

                &.activated {
                    position: relative;
                    margin-bottom: -1px;

                    color: $color-brand-1;

                    background: white;
                    border: 2px solid $color-brand-2;
                    border-bottom: 1px solid white;
                    z-index: 1;
                }
            }

            a:first-of-type() {
                border-left: none !important;
            }

            a:not(:first-of-type) {
                margin-left: -2px;
            }
        }

        .tab-content {
            display: none;
            &.activated {
                display: block;
            }
        }

        .col1 {
            float: left;
            margin-top: 20px;
            margin-bottom: 35px;
            width: 100%;

            @include mappy-bp(mediumish) {
                width: 50%;
                max-width: 380px;
                margin-top: 0;
            }

            h3 {
                padding: 45px 0 4px 0;
            }

            .intro {
                display: inline-block;
                margin: 0px 0 40px 0;
                font-size: 16px;
                line-height: 24px;
                color: #666;
            }

            p {
                margin: 0px 0 4px 0;
                font-size: 14px;
                line-height: 24px;

                @include mappy-bp(mediumish) {
                    margin: 0px 0 8px 0;
                    font-size: 16px;
                }
            }

            a {
                display: block;
            }

            .phone {
                margin: 0 0 15px;
                font-size: 22px;
                font-weight: $font-bold;
                line-height: 20px;
                color: $color-brand-1;
            }

            .email {
                font-size: 18px;
                font-weight: $font-normal;
                color: $color-brand-1;
                line-height: 20px;
                @include mappy-bp(mediumish) {
                    font-size: 20px;
                }
            }

        }

        .col2 {
            float: left;
            padding: 20px 0 0 0;
            width: 100%;

            @include mappy-bp(mediumish) {
                float: right;
                width: 50%;
                max-width: 460px;
                padding: 0px;
            }
        }

        .map-area {
            display: inline-block;
            width: 100%;
            margin: 10px 0 30px 0;
            @include mappy-bp(mediumish) {
                margin: 0 0 25px 0;
            }

            .map {
                float: left;
                margin: auto;
                width: 100%;
                height: 300px;
            }
        }

        .location-explanation {
            p {
                font-size: 12px;
                font-weight: 300;
                line-height: 21px;
                color: #4a3c37;
            }
        }

    }//CONTACT-CONTAINER
    
}