/*********************************************
:: Our Team Page                   
********************************************/
.page-team {

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 0 0 80px;

        @include mappy-bp(mediumish){
            padding: 0 0 90px;
        }

        h2 {
            font-size: 22px;
            font-weight: $font-light;
            color: $color-brand-1;

            @include mappy-bp(mediumish){
                font-size: 26px;
            }
        }

        .team-module {
            display: inline-block !important;
        }
    }
    /********************************************/

}
