/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	height: $header-height-mobile;
	z-index: 99;

	@include mappy-bp(mediumish){
		height: $header-height-desktop;
	}

	.logo {
		position: absolute;
		top: 5px;
		left: 6%;
		bottom: 5px;
		transition: none;

		@include mappy-bp(mediumish){
			top: 15px;
			left: 0;
			width: 160px;
		}

		img {
			width: 145px;
			height: 95px;
			@include mappy-bp(mediumish){
				width: 160px;
				height: 102px;	
			}
		}
	}
 
	nav {
		width: 100%;
		padding: 6px 0 10px;

		text-align: right;
		
		border-bottom: 9px solid $color-brand-2;

		@include mappy-bp(max-width mediumish){
			display: none;
		}

		li { 
			position: relative;
			display: inline-block;
			margin: 0;
		}

		a {
			display: inline-block;
			vertical-align: middle;
			margin-left: 19px;

			font-size: 13px;
			line-height: 20px;
			color: $color-brand-1;
			
			&:hover {
				opacity: 0.7;
			}

			&.selected {
				color: $color-brand-2; 
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}
		}

		li.tel {
			a {
				margin-left: 28px;
				font-size: 20px;
				font-weight: 400;
				color: #4a3c37;
			}
		}
	}

	.treatment {
		position: absolute;
		top: 124px;
		left: 0px;
		right: 0px;

		width: 88%;
		max-width: 313px;
		margin: auto;
		padding-bottom: 3px;

		font-size: 21px;
		font-weight: 400;
		line-height: 32px;
		letter-spacing: 0.34px;
		text-align: center;
		color: #4a3c37;

		border-bottom: 2px solid #f7d3c4;

		@include mappy-bp(mediumish){
			top: 64px;
			left: 320px;
			right: auto;

			width: auto;
			text-align: left;
		}

		span {
			vertical-align: middle;
			color: #979797;
		}
	}

	.tel-mob {
		display: inline-block;
		position: absolute;
		top: 10px;
		right: 3%;

		font-size: 12px;
		line-height: 14px;
		letter-spacing: 2px;
		color: $color-brand-1;

		@include mappy-bp(mediumish){
			display: none;
		}
	}

	.hamburger {
        position: absolute;
        top: 35px; 
		right: 3%;

		width: 26px;
    	height: 17px;

		span {
			font-size: 17px;
			color: black;
		}

        &:focus {
            outline: none;
        }
    }
}
.announcement{
    background-color: #e05740; 
    min-height: 50px; 
    display:none; 
    color: #ffffff !important; 
    padding-top: 10px; 
    font-size:13px !important;
    display: flex !important;
    padding-bottom: 10px;
  }
  .announcement-width{
    max-width: 100%;
  }
  
  .announcement-content{
    float: left; 
    display: inline-block; 
    align-content: center; 
    margin: auto; width: 90%; 
    text-align: center; 
    min-width: 200px;
  }
  
  .announcement-close{
    float:right; 
    position: absolute;
    top: 4%;
    right: 4%;
  }
  
  .announcement-link{
    font-size:13px !important; 
    color: #ffffff !important; 
    text-decoration: underline !important;
  }
  
  .banner-close-btn{
    float: right; 
    padding-top: 6px;
  }
/********************************************/