/*********************************************
:: Before & afters Page                   
********************************************/
.page-before-afters {

    .title-module {
       @include mappy-bp(mediumish){
            padding: 35px 0 10px 0;
        } 
    }

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 0 0 80px;

        @include mappy-bp(mediumish){
            padding: 0 0 90px;
        }

        .dropdown {
            width: 100%;

            .selectric-wrapper {
                float: left;
                @include mappy-bp(mediumish){
                    float: right;
                }
            }
        }

        .container {
            display: inline-block;
            width: 100%;
            padding: 40px 0 20px;

            @include mappy-bp(mediumish){
                padding: 82px 0 40px;
            }

            article {
                display: inline-block;
                width: 100%;
                margin-bottom: 40px;

                @include mappy-bp(mediumish){
                    margin-bottom: 80px;
                }

                .image {
                    position: relative;
                    lost-column: 1/2 2 20px;

                    span {
                        position: absolute;
                        bottom: -18px;

                        padding: 9px 15px;
                        font-size: 16px;
                        font-weight: $font-light;
                        letter-spacing: 0.21px;
                        color: black;

                        background: white;
                        border-bottom: 2px solid $color-brand-1;
                    }
                }

                .before span {
                    right: 60px;
                }

                .after span {
                    left: 60px;
                }
            }
        }
    }
    /********************************************/

}
