/*********************************************
:: About Page                   
********************************************/
.page-about {

    .image-container {
        position: relative;
        display: inline-block;
        width: 100%;
        text-align: center;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 80%;

            background: $color-brand-1;
        }

        img {
            position: relative;
            width: 88%;
            float: right;
            z-index: 2;

            @include mappy-bp(mediumish){
                width: 100%;
                margin-bottom: 50px;
            }
        }

    }
    /********************************************/

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 38px 0 80px;

        @include mappy-bp(mediumish){
            padding: 70px 0 90px;
        }

        .col1 {
            width: 100%;
            float: left;

            @include mappy-bp(mediumish){
                width: 65%;
                padding-right: 70px;
            }
        }

        .col2 {
            float: left;
            width: 100%;

            @include mappy-bp(mediumish){
                float: right;
                width: 25%;
            }
        }

    }
    /********************************************/

}
