/*********************************************
:: Dropdown Module
********************************************/
.dropdown-module {
    position: absolute;
    top: 175px;
    left: 0;
    right: 0;
    z-index: 999;

	display: none;
	width: 88%;
    margin: auto;
	padding: 30px 0;

    text-align: center;

    background: white;
    border: 1px solid #eeeeee;
    box-shadow: 0 2px 4px rgba(172, 172, 172, 0.5);

	@include mappy-bp(mediumish){
        width: 100%;
        top: 180px;
		padding: 50px 0;

        text-align: left;
	}

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -7px;
        right: 0;
        left: 0;

        width: 19px;
        height: 19px;
        margin: auto;

        background: white;
        transform: rotate(45deg);
        box-shadow: -3px -3px 4px -2px rgba(172, 172, 172, 0.5);
    }

    .close {
        position: absolute;
        top: 4%;
        right: 4%;

        span {
            font-size: 14px;
            color: #666;
        }
    }

    .col {
        width: 100%;
        float: left;

        a.parent {
            display: inline-block;
            width: 80%;
            margin-bottom: 20px;
            padding: 0 0 6px;
            font-size: 17px;
            font-weight: $font-light;
            color: $color-brand-1;

            border-bottom: 2px solid $color-brand-2;

            @include mappy-bp(mediumish){
                padding: 0 0 8px;
		        font-size: 18px;
	        }

            span {
                color: #666;
            }
        }

        .children {
            width: 100%;
            @include mappy-bp(medium){
                column-count: 4;
                column-gap: 30px;
            }
            a {
                display: inline-block;
                width: 100%;
                margin-bottom: 5px;
                
                font-size: 14px;
                font-weight: $font-light;
                line-height: 28px;
                color: black;
            }
        }
    }

}
/********************************************/