/*********************************************
:: Team Module                    
********************************************/
.team-dropdown-module {
    position: absolute;
    top: 66px;
    left: 0;
    display: none;

    width: 100%;

    background: white;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    z-index: 999;

    .close {
        position: absolute;
        top: 4%;
        right: 4%;

        span {
            font-size: 14px;
            color: #666;
        }
    }

    .content-slider-width:before {
        content: "";
        display: block;
        position: absolute;
        top: -39px;
        right: 265px;

        width: 19px;
        height: 19px;
        margin: auto;

        background: white;
        transform: rotate(45deg);
        border: 1px solid #979797;
        z-index: -1;
    }

    .categories {
        width: 100%;
        padding: 30px 0 10px;
        background: white;
        border-bottom: 2px solid $color-brand-2;

        a {
            padding: 16px 20px;
            font-size: 16px;
            color: #4a4a4a;
            transition: all 0.0s ease-in-out;

            &.active {
                color: $color-brand-1;
                background: white;
                border: 2px solid $color-brand-2;
                border-bottom: none;
                border-radius: 3px;
            }
        }
    }

    .team-module {
        position: relative;
        float: left;
        padding: 60px 0 20px;
        background: white;
        z-index: 1;

        article {

            a.image {
                text-align: center;
            }
            a.image, a.image img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                margin: 0 auto 20px;
            }

            h3 {
                width: 100%;
                margin: 0 auto 6px;
                font-size: 16px;
            }
        }
    }

    .tab-content {
        display: none;

        &.active {
            display: block;
        }
    }
    
}

.team-module {
    display: inline-block;
    width: 100%;
    padding: 20px 0;

    @include mappy-bp(mediumish){
        padding: 20px 0;
    }

    article {
        lost-column: 1/2 2 20px;
        margin-bottom: 40px;
        text-align: center;

        @include mappy-bp(mediumish){
            lost-column: 1/3 3 20px;
        }
        @include mappy-bp(mediumish){
            lost-column: 1/4 4 20px;
        }

        a.image {
            display: inline-block;
            width: 160px;
            height: 160px;
            margin: 0 auto 20px;
            border-radius: 50%;
            @include mappy-bp(mediumish){
                width: 200px;
                height: 200px;
            }

            img {
                position: relative;
                width: 160px;
                height: 160px;
                @include mappy-bp(mediumish){
                    width: 200px;
                    height: 200px;
                }
            }

            img:before {
                content: '';
                display: block;
                position: absolute;
                height: 160px;
                width: 160px;
                background-image: url(http://via.placeholder.com/160x160);
                border-radius: 50%;
                @include mappy-bp(mediumish){
                    width: 200px;
                    height: 200px;
                    background-image: url(http://via.placeholder.com/200x200);
                }
            }
        }

        h3 {
            margin-bottom: 6px;
            font-size: 18px;
            font-weight: $font-bold;
            line-height: 24px;
            color: #212121;
        }

        p {
            margin-bottom: 5px;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            color: $color-brand-2;
        }

        a {
            font-size: 13px;
            font-weight: 300;
            line-height: 24px;
            text-transform: uppercase;
            color: #666;
        }
    }

}
/********************************************/