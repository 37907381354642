/*********************************************
:: Our Team Detail Page                   
********************************************/
.page-team-detail {

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 0 0 80px;

        @include mappy-bp(mediumish){
            padding: 40px 0 90px;
        }

        .col1 {
            float: left;
            width: 100%;

            @include mappy-bp(mediumish){
                width: 400px;
            }

            img {
                display: inline-block;
                width: 200px;
                height: 200px;
                margin: 0 auto 20px;
                border-radius: 50%;
                @include mappy-bp(mediumish){
                    width: 320px;
                    height: 320px;
                }
            }
        }

        .col2 {
            float: left;
            width: 100%;

            @include mappy-bp(mediumish){
                width: calc(100% - 400px);
            }

            h3 {
                margin-bottom: 6px;
                font-size: 28px;
                font-weight: $font-light;
                line-height: 28px;
                color: $color-brand-1;
            }

            p.position {
                width: 100%;
                display: inline-block;
                margin-bottom: 17px;
                padding-bottom: 23px;
                
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #666;

                border-bottom: 2px solid $color-brand-2;
            }
        }
    }
    /********************************************/

}
