/*********************************************
:: Treatment Detial Page
********************************************/
.page-treatment-detail {

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 38px 0 80px;

        @include mappy-bp(mediumish){
            padding: 28px 0 80px;
        }

        .content {
            h1.title {
                margin-bottom: 20px;
                font-size: 30px;
                font-weight: $font-light;
                @include mappy-bp(mediumish){
                    margin-bottom: 32px;
                    font-size: 34px;
                }
            }
        }

        .right {
            text-align: center;
            @include mappy-bp(mediumish){
                float: right;
                padding: 0 0 40px 98px;
            }

            img {
                width: 160px;
                height: 160px;
                margin: 0 auto;
                border-radius: 50%;
                @include mappy-bp(mediumish){
                    margin-bottom: 43px;
                    width: 300px;
                    height: 300px;
                }
            }

            nav {
                float: right;
                width: 155px;
                padding: 68px 0 48px 32px;

                text-align: left;
                border-left: 2px solid $color-brand-2;

                a {
                    display: block;
                    margin-bottom: 20px;

                    font-size: 16px;
                    font-weight: $font-normal;
                    line-height: 18px;
                    color: #4a4a4a;

                    &.active {
                        position: relative;
                        font-size: 18px;
                        font-weight: $font-semibold;
                        color: $color-brand-1;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 9px;
                            left: -33px;

                            width: 23px;
                            height: 2px;
                            background: $color-brand-2;
                        }
                    }
                }
            }
        }

    }
    /********************************************/

    .dark-container {
        display: inline-block;
        width: 100%;
        padding: 21px 0 10px;

        background: $color-brand-1;

        @include mappy-bp(mediumish){
            padding: 70px 0 40px;
        }

        h3.special-title {
            font-size: 23px;
            color: white;

            @include mappy-bp(mediumish){
                display: table;
                width: auto;
                margin: -113px 0 23px;
                padding: 24px 30px 28px 30px;

                font-size: 30px;
                line-height: 36px;

                background: $color-brand-1;
                border: 1px solid white;
                border-radius: 6px;
                transform: skew(-5deg);

                span {
                    display: inline-block;
                    transform: skew(5deg);
                }
            }
        }

        h1,h2,h3,h4,h5,h6, p, li, td, th, a, mark, time, dl, label, address {
            color: white;
        }

        ul li {
            margin: 0 0 5px 20px;
            list-style-type: disc;
        }
    }
    /********************************************/

    .white-container {
        display: inline-block;
        width: 100%;
        padding: 21px 0 10px;

        background: white;

        @include mappy-bp(mediumish){
            padding: 70px 0 40px;
        }

        h3.special-title {
            font-size: 23px;
            color: #4a4a4a;

            @include mappy-bp(mediumish){
                display: table;
                width: auto;
                margin: -113px 0 23px;
                padding: 24px 30px 28px 30px;

                font-size: 30px;
                line-height: 36px;

                background: white;
                border: 1px solid $color-brand-1;
                border-radius: 6px;
                transform: skew(-5deg);

                span {
                    display: inline-block;
                    transform: skew(5deg);
                }
            }
        }

    }
    /********************************************/


    .whats-next-container {
        display: inline-block;
        width: 100%;
        padding: 23px 0 29px;

        text-align: center;
        background: #f8d3c4;

        @include mappy-bp(mediumish){
            padding: 56px 0 65px;
        }

        h3.special-title {
            position: relative;
            margin-bottom: 23px;
            padding-bottom: 21px;

            font-size: 23px;
            font-weight: $font-light;
            line-height: 24px;
            color: #4a4a4a;

            @include mappy-bp(mediumish){
                font-size: 30px;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;

                width: 185px;
                height: 2px;
                margin: auto;

                background: #e05740;
            }
        }

    }
    /********************************************/

    .results-container {
        display: inline-block;
        width: 100%;
        padding: 40px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 68px 0;
        }

        h3.special-title {
            margin-bottom: 40px;
            font-size: 23px;
            font-weight: $font-light;
            color: $color-brand-1;

            @include mappy-bp(mediumish){
                margin-bottom: 59px;
                font-size: 36px;
            }
        }

        article {
            display: inline-block;
            width: 100%;

            .image {
                position: relative;
                lost-column: 1/2 2 20px;
                margin-bottom: 30px;

                span {
                    position: absolute;
                    bottom: -18px;

                    padding: 9px 15px;
                    font-size: 16px;
                    font-weight: $font-light;
                    letter-spacing: 0.21px;
                    color: black;

                    background: white;
                    border-bottom: 2px solid $color-brand-1;
                }
            }

            .before span {
                right: 60px;
            }

            .after span {
                left: 60px;
            }
        }
    }
    /********************************************/

    .tabs-container {
        display: inline-block;
        width: 100%;

        .tabs {
            width: 100%;
            max-width: 620px;
            padding: 40px 0;

            @include mappy-bp(mediumish){
                padding: 50px 0 70px 0;
            }

            a {
                display: inline-block;
                vertical-align: top;
                width: 105px;
                height: 105px;
                margin: 0 10px 10px 0;

                font-size: 20px;
                font-weight: $font-light;
                line-height: 26px;
                text-align: center;
                color: #4a4a4a;

                border: 1px solid $color-brand-1;
                border-radius: 50%;

                @include mappy-bp(mediumish){
                    width: 160px;
                    height: 160px;
                    margin: 0 30px 30px 0;

                    font-size: 24px;
                    line-height: 34px;
                }

                .center {
                    position: relative;
                    display: inline-block;
                    top: 50%;
                    transform: translateY(-50%);

                    width: 70%;
                    margin: 0 15%;

                    @include mappy-bp(mediumish){
                        width: 60%;
                        margin: 0 20%;
                    }

                    p {
                        color: #4a4a4a;
                        margin: 0;
                    }
                }
            }

            a.active {
                background: $color-brand-1;

                p {
                    color: white;
                }
            }
        }

        .tab-content {
            display: none;
            float: left;
            width: 100%;
            padding: 23px 0;
            background: $color-brand-1;

            @include mappy-bp(mediumish){
                padding: 70px 0;
            }

            &.active {
                display:inline-block;
            }

            .col1 {
                float: left;
                width: 100%;

                @include mappy-bp(mediumish){
                    width: 66%;
                }

                h1,h2,h3,h4,h5,h6, p, li, td, th, a, mark, time, dl, label, address {
                    color: white;
                }
            }

            .col2 {
                float: left;
                width: 100%;
                text-align: center;

                @include mappy-bp(mediumish){
                    width: 34%;
                }

                img {
                    width: 150px;
                    margin: 0 auto;

                    border-radius: 50%;

                    @include mappy-bp(mediumish){
                        float: right;
                        width: 300px;

                    }
                }
            }
        }

        h3.special-title {
            font-size: 23px;
            color: white;

            @include mappy-bp(mediumish){
                display: table;
                width: auto;
                margin: -113px 0 23px;
                padding: 24px 30px 28px 30px;

                font-size: 30px;
                line-height: 36px;

                background: $color-brand-1;
                border: 1px solid white;
                border-radius: 6px;
                transform: skew(-5deg);

                span {
                    display: inline-block;
                    transform: skew(5deg);
                }
            }
        }
    }

    ul li {
        margin: 0 0 5px 20px;
        list-style-type: disc;
    }
}
