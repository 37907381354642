/*********************************************
:: Typography
********************************************/

html, body {
    font-family: $font-family-1;
    font-weight: $font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
    margin-bottom: 20px;
    
    font-family: $font-family-1 !important;
    font-weight: $font-light;
    color: $color-brand-1;

    @include mappy-bp(mediumish){
        margin-bottom: 32px;
    }
}   

h1 {
    font-size: 28px;
    line-height: 32px;
    @include mappy-bp(mediumish){
        font-size: 44px;
        line-height: 50px;
    }
}

h2 {
    font-size: 18px;
    line-height: 28px;
    @include mappy-bp(mediumish) {
        font-size: 26px;
    }
}

h3 {
    font-size: 16px;
    line-height: 28px;
    @include mappy-bp(mediumish) {
        font-size: 24px;
    }
}

h4 {
    font-size: 16px;
    line-height: 24px;
    @include mappy-bp(mediumish){
        font-size: 20px;
    }
}

h5 {
    font-size: 16px;
    line-height: 24px;
    @include mappy-bp(mediumish){
        font-size: 18px;
    }
}

h6 {
    font-size: 16px;
    line-height: 24px;
    @include mappy-bp(mediumish){
        font-size: 16px;
    }
}

/*******************************************/
//Main

p, li, td, th, a, mark, time, dl, label, address {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 24px;
    color: #4a4a4a;

    @include mappy-bp(mediumish){
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 27px;
    }

    a, li, p, mark, cite, time {
        font-size: 1em;
    }
}

a {
    margin: 0px;
    font-weight: $font-normal;
    text-decoration: none;
    color: $color-link;
    transition: all .5s ease-in-out;

    &:hover, &:focus, &:active{
        color: $color-link-hover;
    }
}

small {
    font-size: 70%;
}

strong, b {
  font-weight: $font-bold;
}

em, i {
  font-style: italic;
}

hr {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 15px;

    background: $color-medium-grey;
    border: none;
    outline: none;
}


/*******************************************/
// Lists

dt {
    font-weight: $font-bold;

    &:after{
        content: ':';
    }
}

dt:after {
  content: ": ";
}


ul li{
    position: relative;
    list-style-type: none;
    text-indent: 0px;
}

ol li {
    list-style-type: decimal;
}



/*******************************************/
// Code

code, pre, samp, kbd, var{
    margin: 0px;
    padding: 1px 5px 1px 5px;

    color: white;
    background: #2D747E;
    white-space: pre-wrap;
}

pre{
    padding: 10px;
}

/*******************************************/
// Blockquote

blockquote {
    position: relative;
    display: block;
    padding: 12px 0;

    font-size: 16px;
    font-weight: $font-light;
    line-height: 27px;
    color: #666;

    border-top: 2px solid #f6ccbc;

    @include mappy-bp(mediumish){
        padding: 22px 0;
        font-size: 20px;
        line-height: 33px;
    }
}

/*******************************************/
//Styles for Content Areas (wysiwyg)

.wysiwyg {
  text-align: left;

    img {
        display: block;
        margin: 0 auto 15px auto;
    }

    ul {
        margin: 0 0 20px 25px;
        @include mappy-bp(mediumish){
            margin: 0 0 32px 25px;
        }

        li {
            list-style-type: disc;
            margin-bottom: 5px;
        }
    }

}

.leadcopy, .detail-container p:first-of-type {
    font-size: 15px;
    font-weight: $font-bold;
    line-height: 24px;

    @include mappy-bp(mediumish){
        font-size: 16px;
        line-height: 27px;
    }
}

iframe {
    margin-bottom: 20px;
    width: 100%;
    max-width: 540px;
    min-height: 300px;
    @include mappy-bp(mediumish){
        margin-bottom: 32px;
    }
}

/*******************************************/
//Typekit Trick

.wf-loading h1,
.wf-loading h2,
.wf-loading p,
.wf-loading a{
    visibility: hidden
}