/*********************************************
:: Title Module                    
********************************************/
.title-module {
	position: relative;
	display: inline-block;
	width: 100%;
	padding: 0px 0 28px 0;

	@include mappy-bp(mediumish){
		padding: 35px 0 55px 0;
	}

	.content-max-width {
		@include mappy-bp(max-width mediumish){
			width: 88%;
			margin: auto;
			padding: 0;
		}
	
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			
			height: 1px;
			width: 100%;
			margin: auto;

			background: #CCCCCC;
			z-index: 1;
		}
	}

	h1 {
		position: relative;
		display: inline-block;
		width: auto;
		margin: 0;
		padding: 0 32px 0 0;

		font-size: 30px;
		font-weight: $font-light;
		line-height: 21px;
		text-align: left;
		color: $color-brand-1;

		background: white;
		z-index: 9;

		@include mappy-bp(mediumish){
			margin-left: 188px;
			padding: 0 32px;
			font-size: 34px;
			line-height: 36px;
		}
	}

}
/********************************************/