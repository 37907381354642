/*********************************************
:: Why Page                   
********************************************/
.page-why {

    .detail-container {
        display: inline-block;
        width: 100%;
        padding: 0 0 80px;

        @include mappy-bp(mediumish){
            padding: 0 0 90px;
        }

        .points {
            display: inline-block;
            width: 100%;
            padding: 23px 0;

            @include mappy-bp(mediumish){
                padding: 70px 0;
            }

            .point {
                float: left;
                width: 100%;
                margin-bottom: 15px;
                text-align: center;

                @include mappy-bp(smallish){
                    lost-column: 1/2 2 20px;
                }
                @include mappy-bp(mediumish){
                    lost-column: 1/3 3 60px;
                }

                img {
                    width: 195px;
                    height: 195px;
                    
                    margin: 0 auto 19px;
                    border: 1px solid #efa588;
                    border-radius: 50%;

                    @include mappy-bp(smallish){
                        width: 266px;
                        height: 266px;
                        margin: 0 auto 48px;
                    }
                }

                h3 {
                    position: relative;
                    padding-bottom: 20px;
                    font-size: 20px;
                    font-weight: $font-light;
                    color: $color-brand-1;

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        width: 75px;
                        height: 1px;
                        margin: auto;
                        background: $color-brand-2;
                    }
                }

                .content {
                    max-width: 255px;
                    margin: auto;
                }

                p {
                    font-size: 14px;
                    line-height: 24px;
                }

                p:first-of-type {
                    font-weight: $font-normal;
                }
            }
        }

        .content-block {
            display: inline-block;
            width: 100%;
            text-align: center;

            .title {
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 20px;

                font-size: 25px;
                font-weight: $font-light;
                color: #4a4a4a;
                
                @include mappy-bp(smallish){
                    padding-bottom: 30px;
                    margin-bottom: 30px;

                    font-size: 30px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    width: 250px;
                    height: 1px;
                    margin: auto;
                    background: $color-brand-2;
                }
            }

            p:first-of-type {
                font-weight: $font-normal;
            }
        }

        .herotwo {
            width: 112%;
            max-width: none;
            margin: 0 0 20px -6%;

            @include mappy-bp(smallish){
                width: 100%;
                margin: 40px 0;
            }
        }

    }
    /********************************************/

}
