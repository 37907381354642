/*********************************************
:: Home Page                   
********************************************/
.page-home {

    .hero-container {
        position: relative;
        display: inline-block;
    	width: 100%;
        min-height: 205px; 
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;

        @include mappy-bp(smallish){
            min-height: 300px; 
        }
        @include mappy-bp(mediumish){
            max-width: 1440px;
            min-height: 514px; 
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100px;

            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
            opacity: 0.76;
            z-index: 2;
        }

    	h1 {
            font-size: 25px;
            font-weight: $font-light;
            line-height: 34px;
            letter-spacing: 0.41px;
            color: #979797;
            z-index: 9;

            @include mappy-bp(mediumish){
                font-size: 57px;
                line-height: 60px;
            }
        }

        .wrap {
            position: absolute;
            top: 0px;
            left: 3%;
            z-index: 222;

            width: 50%;

            @include mappy-bp(mediumish){
                top: 185px;
                left: 0;
                max-width: 630px;
            }
        }
    }
    /********************************************/

    .info-container {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 34px 0;
        background: $color-brand-1;
        overflow: visible;

        @include mappy-bp(mediumish){
            padding: 74px 0;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -40px;
            right: -21px;

            width: 77px;
            height: 77px;
            background: url(/images/symbol.svg) no-repeat center;
            background-size: contain;

            opacity: 0.76;
            z-index: 2;

            @include mappy-bp(mediumish){
                bottom: -250px;
                right: 41px;

                width: 377px;
                height: 377px;
            }
        }

        h2 {
            font-size: 20px;
            font-weight: $font-light;
            line-height: 28px;
            color: white;

            @include mappy-bp(mediumish){
                padding-right: 71px;
                font-size: 36px;
                line-height: 48px;
            }
        }
    }
    /********************************************/

    .treatment-container {
        display: inline-block;
        width: 100%;
        padding: 34px 0;

        text-align: center;

        @include mappy-bp(mediumish){
            padding: 90px 0 97px;
            text-align: left;
        }

        .wrap {
            max-width: 700px;
        }

        p.lead {
            padding: 0 0 15px;

            font-size: 18px;
            font-weight: $font-light;
            color: #4a4a4a;

            border-bottom: 1px solid #cccccc;

            @include mappy-bp(mediumish){
                margin-bottom: 29px;
                padding: 0;
                font-size: 24px;
                border-bottom: none;
            }
        }

        article {
            display: inline-block;
            width: 100%;
            max-width: 420px;
            margin: 0 auto;
            padding: 20px 0;

            @include mappy-bp(mediumish){
                max-width: none;
                padding: 50px 0;

                border-bottom: 2px solid #cccccc;
            }

            .col1 {
                float: left;
                width: 100%;
                @include mappy-bp(mediumish){
                    width: 220px;
                }

                img {
                    width: 160px;
                    border-radius: 50%;
                }
            }

            .col2 {
                float: left;
                width: 100%;
                
                @include mappy-bp(mediumish){
                    padding: 5px 0;
                    width: calc(100% - 220px);
                }

                h3 {
                    margin-bottom: 13px;
                    font-size: 22px;
                    font-weight: $font-normal;
                    color: $color-brand-1;
                }

                p {
                    color: black;
                }
            }
        }

        article:last-of-type {
            border-bottom: none;

            @include mappy-bp(mediumish){
                padding: 50px 0 20px;
            }
        }
    }
    /********************************************/

    .info-container-v2 {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 34px 0;

        border-top: 1px solid #CCC;

        @include mappy-bp(mediumish){
            padding: 0 0 100px;
            border-top: none;
        }

        h3.sideways {
            width: 100%;
            color: #4a4a4a;

            @include mappy-bp(mediumish){
                float: left;
                width: 400px;
                padding: 0 19px 0 0;
                
                font-size: 38px;
                font-weight: $font-light;
                line-height: 48px;

                text-align: right;
            }
        }

        .content {
            width: 100%;

            @include mappy-bp(mediumish){
                float: left;
                width: calc(100% - 400px);
                padding-top: 10px;
            }

            p {
                margin-bottom: 15px;
                font-size: 14px;
                line-height: 24px;

                &:first-of-type {
                    font-size: 16px;
                    font-weight: $font-semibold;
                    line-height: 25px;
                }
            }
        }
    }
    /********************************************/
}
