/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    position: relative;
    width: 100%;
    min-height: $footer-height-mobile;
    margin: 0 auto;

    background: white;
    border-top: 9px solid $color-brand-2;
    
    @include mappy-bp(mediumish){
        max-width: 940px;
        min-height: $footer-height-desktop;
    }

    .footer-links {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 30px 0;
        @include mappy-bp(mediumish){
            padding: 101px 0 85px;

            &:before {
                content: '';
                position: absolute;
                bottom: 80px;
                right: 0;

                width: 183px;
                height: 181px;
                background: url(/images/footer-logo.png) no-repeat center;
                background-size: cover;
            }
        }


        .links {
            float: left;
            width: 621px;
            padding-right: 50px;

            .cat {
                display: inline-block;
                width: 100%;
                margin-bottom: 22px;
                
                @include mappy-bp(medium){
                    columns: 3;
                    column-gap: 50px;
                }

                a {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 5px;

                    font-size: 15px;
                    line-height: 20px;
                    color: $color-brand-1;
                }

                // a.parent {
                //     margin-bottom: 22px;
                //     font-weight: $font-semibold;
                //     letter-spacing: 1.3px;
                // }
            }
        }

        .contact {
            position: relative;
            float: left;
            width: 100%;

            @include mappy-bp(mediumish){
                width: calc(100% - 621px);
                min-height: 380px;
                padding: 0 0 0 98px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;

                    width: 2px;
                    height: 100%;
                    background: $color-brand-3;
                }
            }

            a {
                display: inline-block;
            }

            .top {
                margin-bottom: 5px;
                font-size: 36px;
                font-weight: $font-light;
                line-height: 38px;
                color: #4a3c37;
            }

            p {
                width: 70%;
                font-size: 13px;
                font-weight: $font-light;
                line-height: 20px;
                color: #AAAAAA;
                @include mappy-bp(mediumish){
                    width: auto;
                    margin-bottom: 10px;
                    font-size: 15px;
                    color: $color-brand-1;
                }
            }

            .ph {
                margin-bottom: 20px;
                font-size: 23px;
                font-weight: $font-semibold;
                line-height: 30px;
                @include mappy-bp(mediumish){
                    font-size: 30px;
                    line-height: 30px;
                    margin-bottom: 24px;
                }
            }

            .fb {
                margin-top: 10px;
                font-size: 38px;
                color: $color-brand-2;

                @include mappy-bp(max-width mediumish){
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: 0px;
                    margin-top: 0;
                }
            }
        }
    }

    .footer-legal {
        display: inline-block;
        width: 100%;
        
        p, a {
            float: left;
            width: 50%;

            font-size: 10px;
            line-height: 10px;
            text-align: left;
            color: #9b9b9b;

            @include mappy-bp(mediumish){
                float: none;
                display: inline-block;
                width: auto;

                text-align: center;
            }
        }
 
        p.copyright {
            margin-bottom: 5px;

            @include mappy-bp(mediumish){
                float:left;
                margin-bottom: 0px;
            }

            a {
                font-size: 11px;
                line-height: 11px;
                color: $color-brand-4;
            }
        }

        a.attribution {
            float:right;
            text-align: right;
        }
    }
}
